import React, { KeyboardEvent, useState } from 'react';
import { getHasOffersGroup } from 'redux/modules/search-and-browse/selectors/get-filter-groups';
import { useSelector } from 'react-redux';
import { Toggle } from '@johnlewispartnership/wtr-ingredients/ingredients/Toggle';
import type { Group, Value } from 'components/Search/Filters/types';
import classNames from 'classnames';

import { yieldToMain } from 'utils/yield-to-main';
import styles from './OffersToggle.scss';

interface OffersToggleProps {
  disabled: boolean;
  onChange: (group: Group, value: Value) => void;
}

const OfferToggle = ({ disabled, onChange }: OffersToggleProps) => {
  const offerGroup = useSelector(getHasOffersGroup);
  const [checked, setChecked] = useState(offerGroup?.applied);

  if (!offerGroup) return null;

  const handleChange = async () => {
    setChecked((toggle: boolean) => !toggle);
    await yieldToMain();
    onChange(
      {
        name: 'OFFER_TYPE',
        allowMultiple: true,
        label: '',
        options: [],
        value: '',
      },
      'promotionflag',
    );
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleChange();
    }
  };

  return (
    <article className={styles.offerControlWrapper}>
      {/* required as import from ingredients not playing well with added label  */}
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label
        className={classNames(styles.offerText, {
          [styles.disabled]: disabled,
        })}
        htmlFor="offers-toggle"
      >
        Only offers
      </label>
      <Toggle
        id="offers-toggle"
        checked={checked}
        disabled={disabled}
        data-testid="offers_toggle"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        showHide={false}
      />
    </article>
  );
};

export default OfferToggle;
