import React, { useCallback, useState } from 'react';

import { Button } from '@johnlewispartnership/wtr-ingredients/ingredients/Button';
import { Filter } from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import FilterModal from 'components/Search/Filters/FilterModal';
import type { AppliedFilters, Group, Groups, Value } from 'components/Search/Filters/types';
import { scrollIntoViewAndFocusById } from 'utils/scroll';

import styles from './FiltersMobile.scss';

interface FiltersMobileProps {
  appliedFilters?: AppliedFilters;
  groups?: Groups;
  disabled?: boolean;
  onChange: (group: Group, value: Value) => void;
  onClear: () => void;
  stickyFilter: boolean;
}

const filterIcon = <Filter size="small" />;

const FiltersMobile = ({
  appliedFilters = [],
  groups = [],
  disabled = false,
  onChange,
  onClear,
  stickyFilter,
}: FiltersMobileProps) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const closeModal = useCallback(() => {
    setModalIsOpen(false);
    scrollIntoViewAndFocusById('mobile-filters-cta-static', { top: 0, topOffset: 60 });
  }, [setModalIsOpen]);

  const openModal = useCallback(() => {
    setModalIsOpen(true);
  }, [setModalIsOpen]);

  const label = `Filter${appliedFilters.length ? ` (${appliedFilters.length})` : ''}`;
  const filtersButtonId = `mobile-filters-cta-${stickyFilter ? 'sticky' : 'static'}`;

  return (
    <>
      <Button
        id={filtersButtonId}
        className={styles.filterButton}
        disabled={disabled}
        label={label}
        onClick={() => {
          openModal();
        }}
        startIcon={filterIcon}
        theme="secondary"
        width="full"
      />
      <FilterModal
        groups={groups}
        disabled={disabled}
        isOpen={modalIsOpen}
        onChange={onChange}
        onClear={onClear}
        onClose={closeModal}
        values={appliedFilters}
      />
    </>
  );
};

export default FiltersMobile;
