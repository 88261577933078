import React from 'react';
import Modal from 'react-modal';

import Button from '@johnlewispartnership/wtr-ingredients/ingredients/Button';
import EventMonitor from 'components/EventMonitor/EventMonitor';
import FilterModalPanel from 'components/Search/Filters/FilterModalPanel';
import OfferToggle from 'components/Search/Filters/OfferToggle';
import type { Group, Value, Groups } from 'components/Search/Filters/types';

import classNames from 'classnames';
import styles from './FilterModal.scss';
import FilterDisclaimer from '../FilterDisclaimer';

type FilterModalProps = {
  groups?: Groups;
  heading?: string;
  disabled?: boolean;
  isOpen?: boolean;
  onChange: (group: Group, value: Value) => void;
  onClear: () => void;
  onClose: () => void;
  values: {
    id: string;
    text: string;
  }[];
};

const FilterModal = ({
  groups = [],
  heading = 'Filter by',
  disabled = false,
  isOpen = false,
  onChange,
  onClear,
  onClose,
  values = [],
}: FilterModalProps) => (
  <Modal
    className={styles.content}
    closeTimeoutMS={300}
    contentLabel="Selected Filters"
    isOpen={isOpen}
    onRequestClose={onClose}
    overlayClassName={styles.overlay}
    bodyOpenClassName={classNames('ReactModal__Body--open', 'mobileFiltersModal')}
  >
    <header className={styles.header}>
      <h3>{heading}</h3>
      <button type="button" className={styles.close} onClick={onClose} aria-label="close" />
    </header>
    <div className={styles.offersWrapper}>
      <OfferToggle disabled={disabled} onChange={onChange} />
    </div>
    <div data-test="accordion-filters" className={styles.body}>
      <FilterModalPanel groups={groups} disabled={disabled} onChange={onChange} />
      <FilterDisclaimer mobile />
    </div>
    <footer className={styles.footer} data-test="accordion-footer">
      <div className={styles.button}>
        <div className={styles.done}>
          <EventMonitor
            actionType="close"
            originComponent="FilterFormNew"
            shortDescription="close filter options"
          >
            <Button label="Apply" onClick={onClose} width="full" />
          </EventMonitor>
        </div>
        {values?.length > 0 && (
          <div className={styles.clear}>
            <EventMonitor
              actionType="reset"
              originComponent="FilterFormNew"
              shortDescription="clear filters"
            >
              <Button
                disabled={disabled}
                label="Clear all"
                theme="secondary"
                onClick={onClear}
                width="full"
              />
            </EventMonitor>
          </div>
        )}
      </div>
    </footer>
  </Modal>
);

FilterModal.displayName = 'FilterModal';

export default FilterModal;
